import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../UserContext";
import TabContext from "../../TabContext";
import { checkPermission, DRA_MODULES, MODULE_ACTIONS } from "../utils/rbacUtils";

// export const TAB_ROUTE_TO_NAME_ID_MAP = {
//   "Add Missing Emails": {
//     path: "/",
//     id: "tab1"
//   },
//   "Pending Shortlist": {
//     path: "/pendingshortlist",
//     id: "tab2"
//   },
//   "Location Heuristics": {
//     path: "/locationheuristics",
//     id: "tab3"
//   },
//   "Deadlink Upload": {
//     path: "/deadlinkupload",
//     id: "tab400"
//   },
//   "Wrong Email": {
//     path: "wrongemail",
//     id: "tab4"
//   },
//   "Assign work": {
//     path: "/assignTasks",
//     id: "tab5"
//   },
//   "Admin": {
//     path: "/admin",
//     id: "tab6"
//   },
//   "Direct Update": {
//     path: "/directUpdate",
//     id: "tab7"
//   },
//   "Opt Out": {
//     path: "/optout",
//     id: "tab8"
//   },
//   "Timeline": {
//     path: "/timeline",
//     id: "tab9"
//   },
//   "Fake cxo heuristics": {
//     path: "/fakecxoheuristics",
//     id: "tab10"
//   },
//   "Fetch Company News and Events": {
//     path: "/news",
//     id: "tab11"
//   },
//   "Leadership Page Validation": {
//     path: "/leadershipPageValidation",
//     id: "tab12"
//   },
//   "PE Copilot Searches": {
//     path: "/peCopilotSearches",
//     id: "tab13"
//   },
//   "RT Copilot Searches": {
//     path: "/rtCopilotSearches",
//     id: "tab14"
//   },
//   "Dummy Profile Creator": {
//     path: "/DummyProfileCreator",
//     id: "tab15"
//   }
// };

const FULL_TIME_EMPLOYEES_EMAILS = ["@findem.ai"];
const INTERNS_EMAILS = ["@findem-research.com"];
const KEY_EMAILS = [
    "hari@findem.ai",
    "dnyanesh@findem.ai",
    "ravi@findem.ai",
    "prateek.kumar@findem.ai",
    "yuganshu@findem.ai",
    "balraj.anand@findem.ai",
    "bhargavi@findem.ai",
    "nimisha@findem.ai",
    "gowtham@findem.ai"
];

export const TAB_ROUTE_TO_NAME_ID_MAP = {
  "/": {
    id: "tab1",
    name: "Add Missing Emails",
    permissionRequired: true,
    module: DRA_MODULES.addMissingEmails,
  },
  "/pending-shortlist": {
    id: "tab2",
    name: "Pending Shortlist",
    permissionRequired: true,
    module: DRA_MODULES.pendingShortList,
  },
  "/location-heuristics": {
    id: "tab3",
    name: "Location Heuristics",
    hide: true,
  },
  "/deadlink-upload": {
    id: "tab400",
    name: "Deadlink Upload",
    hide: true,
  },
  "/wrong-email": {
    id: "tab4",
    name: "Wrong Email",
    permissionRequired: true,
    module: DRA_MODULES.wrongEmails,
  },
  "/assign-tasks": {
    id: "tab5",
    name: "Assign work",
    permissionRequired: true,
    module: DRA_MODULES.assignWork,
  },
  "/admin": {
    id: "tab6",
    name: "Admin",
    permissionRequired: true,
    module: DRA_MODULES.admin,
  },
  "/direct-update": {
    id: "tab7",
    name: "Direct Update",
    permissionRequired: true,
    module: DRA_MODULES.directUpdate,
  },
  "/opt-out": {
    id: "tab8",
    name: "Opt Out",
    permissionRequired: true,
    module: DRA_MODULES.optOut,
  },
  "/timeline": {
    id: "tab9",
    name: "Timeline",
    permissionRequired: true,
    module: DRA_MODULES.timeLine,
  },
  "/fake-cxo-heuristics": {
    id: "tab10",
    name: "Fake cxo heuristics",
    hide: true,
    
  },
  "/news": {
    id: "tab11",
    name: "Fetch Company News and Events",
    permissionRequired: true,
    module: DRA_MODULES.news,
  },
  "/leadership-page-validation": {
    id: "tab12",
    name: "Leadership Page Validation",
    hide: true,
  },
  // "/pe-copilot-searches": {
  //   id: "tab13",
  //   name: "PE Copilot Searches",
  //   allowedEmails: FULL_TIME_EMPLOYEES_EMAILS.concat(INTERNS_EMAILS),
  // },
  "/rt-copilot-searches": {
    id: "tab14",
    name: "RT Copilot Searches",
    permissionRequired: true,
    module: DRA_MODULES.rtCopilotSearches,
  },
  "/dummy-profile-creator": {
    id: "tab15",
    name: "Dummy Profile Creator",
    permissionRequired: true,
    module: DRA_MODULES.dummyProfileCreator,
  },
  "/dead-link-alternative-finder": {
    id: "tab16",
    name: "Dead Link Alternative Finder",
    permissionRequired: true,
    module: DRA_MODULES.deadLinkAlternativeFinder,
  },
  "/company-employee-profile-refresh":{
    id: "tab17",
    name:"Company Employee Profile Refresh",
    permissionRequired: true,
    module: DRA_MODULES.companyEmployeeProfileRefresh,
  },
  "/profile-verification-status":{
    id: "tab18",
    name:"Profile Verification Status",
    permissionRequired: true,
    module: DRA_MODULES.profileVerificationStatus,
  },
  "/instant-profile-refresh":{
    id: "tab19",
    name:"Instant Profile Refresh",
    permissionRequired: true,
    module: DRA_MODULES.instantProfileRefresh,
  },
  "/copilot-searches-paychex":{
    id: "tab20",
    name:<div>Copilot Searches - <strong style={{fontStyle:"italic"}}>PAYCHEX</strong></div>,
    permissionRequired: true,
    module: DRA_MODULES.smbCopilotSearches,
  }
};

const TabsHomePage = () => {
  const [activePageId, setActivePageId] = useContext(TabContext);
  const loggedInUserDetails = useContext(UserContext);

  const handleActivePage = (event) => {
    setActivePageId(event.currentTarget.id);
  };

  return (
    <>
      <ul className="nav nav-tabs">
        {Object.keys(TAB_ROUTE_TO_NAME_ID_MAP).map((path) => {
          const tabId = TAB_ROUTE_TO_NAME_ID_MAP[path].id;
          const tabName = TAB_ROUTE_TO_NAME_ID_MAP[path].name;
          const hide = TAB_ROUTE_TO_NAME_ID_MAP[path].hide;
          const module = TAB_ROUTE_TO_NAME_ID_MAP[path].module;
          const permissionRequired = TAB_ROUTE_TO_NAME_ID_MAP[path].permissionRequired

          if (hide) {
            return null;
          }
          
          
          if(permissionRequired && !checkPermission(loggedInUserDetails,module,MODULE_ACTIONS.READ).read){
            return null;
          }

          return (
            <li className="nav-item" key={tabId}>
              <Link
                id={tabId}
                to={path}
                onClick={handleActivePage}
                className={`nav-link ${activePageId === tabId ? "active" : ""}`}
              >
                {tabName}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default TabsHomePage;
