import React, { useEffect, useState } from "react";
// import "./index.css";
import { CloseOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import axios from "axios";
import { PlusOutlined, MinusCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import _ from "lodash";
import { Button, Card, Form, Input, Space, InputNumber, Tabs, Spin, Modal, message, Select, DatePicker, Typography, Checkbox } from "antd";

import ArrOfStrUIHandler from "../ArrOfStrUIHandler";
import TextArea from "antd/lib/input/TextArea";
const DurationCard = ({ name, form }) => {
    const [toPresent, setToPresent] = useState(false);
    useEffect(() => {
        setToPresent(form.getFieldValue(["educations", name, "duration", "to_present"]));
    }, []);
    const handleCheckbox = (e) => {
        const checked = e.target.checked;
        setToPresent(checked);
        if (checked) {
            form.setFieldsValue({
                educations: form.getFieldValue("educations").map((item, index) =>
                    index === name
                        ? {
                              ...item,
                              duration: {
                                  ...item.duration,
                                  end_date: null,
                              },
                          }
                        : item
                ),
            });
        }
    };
    return (
        <Card size="small" title="Duration">
            <Form.Item name={[name, "duration", "start_date"]} label="Start Date">
                <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
            <Form.Item name={[name, "duration", "end_date"]} label="End Date">
                <DatePicker value={null} format="YYYY-MM-DD" disabled={toPresent} allowClear />
            </Form.Item>
            <Form.Item label="To Present" name={[name, "duration", "to_present"]} valuePropName="checked">
                <Checkbox onChange={handleCheckbox} />
            </Form.Item>
        </Card>
    );
};

const EducationForm = ({ form }) => {
    return (
        <Card size="small" title="Educations">
            <Form.List name="educations">
                {(workFields, { add: addWorkExp, remove: removeWorkExp }) => (
                    <>
                        {workFields.map(({ key, name }) => {
                            return (
                                <Card
                                    key={key}
                                    size="small"
                                    title={`Education ${key + 1}`}
                                    extra={<MinusCircleOutlined onClick={() => removeWorkExp(name)} />}
                                >
                                    {/* education details*/}
                                    <Card size="small" title="Education details">
                                        {["degree", "major", "skills"].map((field, idx) => (
                                            <Card key={idx} size="small" title={`${field.charAt(0).toUpperCase() + field.slice(1)}`}>
                                                <Form.List name={[name, "education_details", field]}>
                                                    {(fields, { add, remove }) => (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "start",
                                                                alignItems: "center",
                                                                flexWrap: "wrap",
                                                            }}
                                                        >
                                                            {fields.map(({ key, name, fieldKey }) => (
                                                                <Space key={key} align="baseline">
                                                                    <Form.Item
                                                                        name={[name]}
                                                                        fieldKey={[fieldKey, field]}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: `Please input the ${field}`,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            placeholder={field}
                                                                            addonAfter={
                                                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </Space>
                                                            ))}
                                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                Add {field.charAt(0).toUpperCase() + field.slice(1)}
                                                            </Button>
                                                        </div>
                                                    )}
                                                </Form.List>
                                            </Card>
                                        ))}
                                        <Form.Item label="description" name={[name, "education_details", "description"]}>
                                            <TextArea />
                                        </Form.Item>
                                    </Card>

                                    {/* Duration */}
                                    <DurationCard name={name} form={form} />

                                    {/* school info*/}
                                    <Card size="small" title="School Info">
                                        {["name", "lnkd_school_src_id", "school_id"].map((field, idx) => {
                                            return (
                                                <Form.Item key={idx} label={field} name={[name, "school_info", field]}>
                                                    <Input />
                                                </Form.Item>
                                            );
                                        })}
                                    </Card>
                                </Card>
                            );
                        })}
                        <Button type="dashed" onClick={() => addWorkExp()} block icon={<PlusOutlined />}>
                            Add Education
                        </Button>
                    </>
                )}
            </Form.List>
        </Card>
    );
};

export default EducationForm;
