import React from "react";
import { Navigate, useLocation } from "react-router-dom";

function ProtectedRoutes(props) {
  const { Component, loggedInSessionUser, loggedInUser } = props;
  const { pathname, search } = useLocation();
  if (loggedInSessionUser.name || loggedInUser.name) {
    return <Component {...props} />;
  } else {
    return (
      <Navigate
        to="/login"
        state={{
          pathname: pathname,
          search: search,
        }}
      />
    );
  }
}

export default ProtectedRoutes;
