import React, { useState } from "react";
// import "./index.css";
import { CloseOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, DatePicker, Modal, Select } from "antd";

const { Option } = Select;

const ProfileCreator = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createdProfile, setCreatedProfile] = useState(null);
  const [endDate, setEndDate] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [selectValue, setSelectValue] = useState("Present");

  const initialVal = {
    url: "", // Keep
    uid: "", // Keep
    ref: "", // Keep
    ext_refs: [
      {
        source: "", // Keep
        source_ref: "", // Keep
        url: "", // Keep
      },
    ],
    awards: [
      {
        name: "", // Keep
        date: "", // Keep
        description: "", // Keep
        issuer: "", // Keep
      },
    ],
    certifications: [
      {
        title: "", // Keep
        start: "", // Keep
        end: "", // Keep
        description: "", // Keep
        certificate_authority: "", // Keep
      },
    ],
    educations: [
      {
        title: "", // Keep
        type: "",
        schoolDomain: "", // Keep
        degree: "", // Keep
        location: "", // Keep
        date1: "", // Keep
        date2: "", // Keep
      },
    ],
    patents: [
      {
        title: "", // Keep
        number: "", // Keep
        date: "", // Keep
        description: "", // Keep
        url: "", // Keep
      },
    ],
    positions: [
      {
        title: "", // Keep
        companyName: "", // Keep
        companyDomain: "", // Keep
        roles: [
          {
            title: "", // Keep
            location: "", // Keep
            date1: "", // Keep
            date2: "", // Keep
          },
        ],
      },
    ],
    current_position: [
      { current_position: "" }, // Keep
    ],
    profile: [
      { name: "" }, // Keep
      { location: "" }, // Keep
      { city: "" }, // Keep
      { state: "" }, // Keep
      { country: "" }, // Keep
      { imageUrl: "" }, // Keep
      { gender: "" }, // Keep
      { ethnicity: [""] }, // Keep
    ],
    projects: [
      {
        name: "", // Keep
        description: "", // Keep
        start: "", // Keep
        end: "", // Keep
      },
    ],
    publications: [
      {
        title: "", // Keep
        publisher: "", // Keep
        date: "", // Keep
        summary: "", // Keep
      },
    ],
    created_at: "12-11-2010", // Keep
    updated_at: "21-12-2023", // Keep
    skills: [{ skill: "" }], // Keep
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const profileKeys = initialVal.profile.reduce((acc, curr) => {
    acc.push(Object.keys(curr)[0]);
    return acc;
  }, []);

  const onFinish = (values) => {
    setCreatedProfile(values);
    setIsModalOpen(true);
  };

  const postCreatedProfile = () => {
    // fetchpost method
    console.log(createdProfile);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDateChange = (date, dateString, subField) => {
    if (date) {
      setDateValue(dateString);
      setSelectValue(dateString);
      form.setFieldsValue({ [subField]: dateString });
    }
  };

  const handleSelectChange = (value, subField) => {
    if (value === "Present") {
      setSelectValue("Present");
      form.setFieldsValue({ [subField]: "Present" });
    } else {
      // Open the DatePicker
      // Reset the DatePicker if it was previously set
      form.setFieldsValue({ [subField]: null });
      setSelectValue(null);
    }
  };

  const allowedKeys = ["url", "uid", "ref", "created_at", "updated_at"];

  return (
    <>
      <Form
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 18,
        }}
        form={form}
        name="dynamic_form_complex"
        style={{
          minWidth: 1000,
          maxWidth: 1000,
          marginLeft: "2rem",
          padding: "1rem",
          // border: "1px solid grey",
        }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={initialVal}
      >
        <h4
          style={{
            textAlign: "center",
            marginTop: "1rem",
            fontSize: "40px",
            fontWeight: 600,
            backgroundImage: "linear-gradient(to left, #553c9a, #b393d3)",
            color: "transparent",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
          }}
        >
          Create Profile
        </h4>
        <br />
        <div
          style={{
            display: "flex",
            rowGap: 16,
            flexDirection: "column",
            // border: "2px solid red",
            marginBottom: "1rem",
            padding: "1rem",
            borderRadius: "10px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          {Object.keys(initialVal)
            ?.filter((i) => allowedKeys.includes(i))
            ?.map((i) => {
              const ifDate = ["created_at", "updated_at"];
              return (
                <div key={i}>
                  {ifDate.includes(i) ? (
                    <Form.Item label={i} name={i} valuePropName={"date"}>
                      <DatePicker
                        placeholder={`Select ${i}`}
                        format="YYYY-MM-DD"
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item label={i} name={i}>
                      <Input placeholder={`Enter ${i}`} />
                    </Form.Item>
                  )}
                </div>
              );
            })}
        </div>

        <Form.List name="profile">
          {(fields) => (
            <div
              style={{
                display: "flex",
                rowGap: 16,
                flexDirection: "column",
                // border: "2px solid red",
                marginBottom: "1rem",
                padding: "1rem",
                borderRadius: "10px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <Card size="small" title="Profile" key="profile">
                {fields.map((field) => {
                  // Use the function to capitalize the label
                  const capitalizedLabel = capitalizeFirstLetter(
                    profileKeys[field.key]
                  );

                  return (
                    <div key={field.key}>
                      {field.key !== 7 && (
                        <div
                          style={{ display: "flex" }}
                          className="ant-row ant-form-item"
                        >
                          <div className="ant-col ant-col-6 ant-form-item-label">
                            <label
                              htmlFor={[field.name, profileKeys[field.key]]}
                              title={capitalizedLabel}
                            >
                              {capitalizedLabel}{" "}
                              {/* Use the capitalized label here */}
                            </label>
                          </div>
                          <div className="ant-col ant-col-18 ant-form-item-control">
                            <Form.Item
                              noStyle
                              name={[field.name, profileKeys[field.key]]}
                            >
                              <Input
                                placeholder={`${profileKeys[field.key]}`}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      )}
                      {field.key === 7 ? (
                        <Form.Item label="Ethnicity">
                          <Form.List
                            name={[field.name, "ethnicity"]}
                            placeholder="Ethnicity"
                          >
                            {(subFields, subOpt) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  rowGap: 16,
                                }}
                              >
                                {subFields.map((subField) => (
                                  <div
                                    key={subField.key}
                                    style={{ display: "flex" }}
                                  >
                                    <Form.Item
                                      noStyle
                                      name={[subField.name, "ethnicity"]}
                                    >
                                      <Input placeholder="Ethnicity" />
                                    </Form.Item>
                                    <CloseOutlined
                                      onClick={() => {
                                        subOpt.remove(subField.name);
                                      }}
                                    />
                                  </div>
                                ))}
                                <Button
                                  type="dashed"
                                  onClick={() => subOpt.add()}
                                  block
                                >
                                  + Add Ethnicity
                                </Button>
                              </div>
                            )}
                          </Form.List>
                        </Form.Item>
                      ) : null}
                    </div>
                  );
                })}
              </Card>
            </div>
          )}
        </Form.List>

        <Form.List name="positions">
          {(fields, { add, remove }) => (
            <div
              style={{
                display: "flex",
                rowGap: 16,
                flexDirection: "column",
                marginBottom: "1rem",
                padding: "1rem",
                borderRadius: "10px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              {fields.map((field) => (
                <Card
                  size="small"
                  title={`Position ${field.name + 1}`}
                  key={field.key}
                  extra={
                    <CloseOutlined
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  }
                >
                  <Form.Item label="Title" name={[field.name, "title"]}>
                    <Input placeholder="Title" />
                  </Form.Item>

                  <Form.Item
                    label="Company Name"
                    name={[field.name, "companyName"]}
                  >
                    <Input placeholder="Company Name" />
                  </Form.Item>

                  <Form.Item
                    label="Company Domain"
                    name={[field.name, "companyDomain"]}
                  >
                    <Input placeholder="Company Domain" />
                  </Form.Item>

                  <Form.Item label="Roles">
                    <Form.List name={[field.name, "roles"]}>
                      {(subFields, subOpt) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: 16,
                          }}
                        >
                          {subFields.map((subField) => (
                            <div key={subField.key} style={{ display: "flex" }}>
                              <Form.Item
                                noStyle
                                name={[subField.name, "title"]}
                              >
                                <Input placeholder="Title" />
                              </Form.Item>
                              <Form.Item
                                noStyle
                                name={[subField.name, "location"]}
                              >
                                <Input placeholder="Location" />
                              </Form.Item>
                              <Form.Item
                                noStyle
                                name={[subField.name, "description"]}
                              >
                                <Input placeholder="Description" />
                              </Form.Item>

                              <Form.Item
                                noStyle
                                name={[subField.name, "date1"]}
                              >
                                {/* <Input placeholder="Start date" /> */}
                                <DatePicker
                                  style={{ width: "100%" }}
                                  placeholder="Start Date"
                                  format="YYYY-MM-DD"
                                />
                              </Form.Item>

                              <Form.Item
                                noStyle
                                name={[subField.name, "date2"]}
                              >
                                <Select
                                  value={selectValue}
                                  onChange={handleSelectChange}
                                  style={{ width: "100%" }}
                                  dropdownRender={(menu) => (
                                    <React.Fragment>
                                      {menu}
                                      <div
                                        style={{
                                          padding: "8px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <DatePicker
                                          onChange={handleDateChange}
                                          format="YYYY-MM-DD"
                                          style={{ width: "100%" }}
                                          open={selectValue === null}
                                        />
                                      </div>
                                    </React.Fragment>
                                  )}
                                >
                                  <Option value="Present">Present</Option>
                                  <Option value={dateValue}>End Date</Option>
                                </Select>
                              </Form.Item>
                              <CloseOutlined
                                onClick={() => {
                                  subOpt.remove(subField.name);
                                }}
                              />
                            </div>
                          ))}
                          <Button
                            type="dashed"
                            onClick={() => subOpt.add()}
                            block
                          >
                            + Add Role
                          </Button>
                        </div>
                      )}
                    </Form.List>
                  </Form.Item>
                </Card>
              ))}

              <Button type="dashed" onClick={() => add()} block>
                + Add Positions
              </Button>
            </div>
          )}
        </Form.List>

        <Form.List name="current_position">
          {(fields, { add, remove }) => (
            <div
              style={{
                display: "flex",
                rowGap: 16,
                flexDirection: "column",
                // border: "2px solid red",
                marginBottom: "1rem",
                padding: "1rem",
                borderRadius: "10px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              {fields.map((field) => (
                <Card
                  size="small"
                  title={`Current Position ${field.name + 1}`}
                  key={field.key}
                  extra={
                    <CloseOutlined
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  }
                >
                  <div style={{ display: "flex" }} key={field.key}>
                    <Form.Item noStyle name={[field.name, "current_position"]}>
                      <Input placeholder="Current Position" />
                    </Form.Item>
                  </div>
                </Card>
              ))}

              <Button type="dashed" onClick={() => add()} block>
                + Add Current Position
              </Button>
            </div>
          )}
        </Form.List>

        <Form.List name="educations">
          {(fields, { add, remove }) => (
            <div
              style={{
                display: "flex",
                rowGap: 16,
                flexDirection: "column",
                marginBottom: "1rem",
                padding: "1rem",
                borderRadius: "10px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              {fields.map((field) => (
                <Card
                  size="small"
                  title={`Education ${field.name + 1}`}
                  key={field.key}
                  extra={
                    <CloseOutlined
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  }
                >
                  <>
                    <Form.Item
                      label="School/College Name"
                      name={[field.name, "title"]}
                    >
                      <Input placeholder="School/College Name" />
                    </Form.Item>

                    <Form.Item label="Type" name={[field.name, "type"]}>
                      <Input placeholder="School/College" />
                    </Form.Item>

                    <Form.Item
                      label="School Domain"
                      name={[field.name, "schoolDomain"]}
                    >
                      <Input placeholder="School/College Domain" />
                    </Form.Item>

                    <Form.Item
                      label="Degree Name"
                      name={[field.name, "degree"]}
                    >
                      <Input placeholder="Degree Name" />
                    </Form.Item>

                    <Form.Item label="Location" name={[field.name, "location"]}>
                      <Input placeholder="Location" />
                    </Form.Item>

                    <Form.Item label="Start Date" name={[field.name, "date1"]}>
                      <Input placeholder="Start Date" />
                    </Form.Item>

                    <Form.Item label="End Date" name={[field.name, "date2"]}>
                      <Input placeholder="End Date" />
                    </Form.Item>
                  </>
                </Card>
              ))}

              <Button type="dashed" onClick={() => add()} block>
                + Add Education
              </Button>
            </div>
          )}
        </Form.List>

        <Form.List name="ext_refs">
          {(fields, { add, remove }) => (
            <div
              style={{
                display: "flex",
                rowGap: 16,
                flexDirection: "column",
                marginBottom: "1rem",
                padding: "1rem",
                borderRadius: "10px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              {fields.map((field) => (
                <Card
                  size="small"
                  title={`Ext Ref ${field.name + 1}`}
                  key={field.key}
                  extra={
                    <CloseOutlined
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  }
                >
                  <>
                    <Form.Item label="Source" name={[field.name, "source"]}>
                      <Input placeholder="Source" />
                    </Form.Item>

                    <Form.Item
                      label="Source Ref"
                      name={[field.name, "source_ref"]}
                    >
                      <Input placeholder="Source Ref" />
                    </Form.Item>

                    <Form.Item label="URL" name={[field.name, "url"]}>
                      <>
                        <Input placeholder="URL" />
                        {/* <Button style={{ marginTop: "1rem" }}>Take Action</Button> */}
                      </>
                    </Form.Item>
                  </>
                </Card>
              ))}

              <Button type="dashed" onClick={() => add()} block>
                + Add Ext Refs
              </Button>
            </div>
          )}
        </Form.List>

        <Form.List name="skills">
          {(fields, { add, remove }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                rowGap: 16,
                //   border: "2px solid red",
                marginBottom: "1rem",
                padding: "1rem",
                borderRadius: "10px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              {fields.map((field) => (
                <Card
                  style={
                    {
                      //   border: "2px solid green",
                    }
                  }
                  size="small"
                  title={`Skill ${field.name + 1}`}
                  key={field.key}
                  extra={
                    <CloseOutlined
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  }
                >
                  <div style={{ display: "flex" }} key={field.key}>
                    <Form.Item noStyle name={[field.name, "skill"]}>
                      <Input placeholder="skill" />
                    </Form.Item>
                  </div>
                </Card>
              ))}

              <Button type="dashed" onClick={() => add()} block>
                + Add Skill
              </Button>
            </div>
          )}
        </Form.List>

        <Form.List name="awards">
          {(fields, { add, remove }) => (
            <div
              style={{
                display: "flex",
                rowGap: 16,
                flexDirection: "column",
                marginBottom: "1rem",
                padding: "1rem",
                borderRadius: "10px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              {fields.map((field) => (
                <Card
                  size="small"
                  title={`Award ${field.name + 1}`}
                  key={field.key}
                  extra={
                    <CloseOutlined
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  }
                >
                  <>
                    <Form.Item label="Name" name={[field.name, "name"]}>
                      <Input placeholder="Name" />
                    </Form.Item>

                    <Form.Item label="Date" name={[field.name, "date"]}>
                      <Input placeholder="Date" />
                    </Form.Item>

                    <Form.Item
                      label="Description"
                      name={[field.name, "description"]}
                    >
                      <Input placeholder="Description" />
                    </Form.Item>

                    <Form.Item label="Issuer" name={[field.name, "issuer"]}>
                      <Input placeholder="Issuer Name" />
                    </Form.Item>
                  </>
                </Card>
              ))}

              <Button type="dashed" onClick={() => add()} block>
                + Add Awards
              </Button>
            </div>
          )}
        </Form.List>

        <Form.List name="certifications">
          {(fields, { add, remove }) => (
            <div
              style={{
                display: "flex",
                rowGap: 16,
                flexDirection: "column",
                marginBottom: "1rem",
                padding: "1rem",
                borderRadius: "10px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              {fields.map((field) => (
                <Card
                  size="small"
                  title={`Certification ${field.name + 1}`}
                  key={field.key}
                  extra={
                    <CloseOutlined
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  }
                >
                  <>
                    <Form.Item label="Title" name={[field.name, "title"]}>
                      <Input placeholder="Title" />
                    </Form.Item>

                    <Form.Item label="Start Date" name={[field.name, "start"]}>
                      <Input placeholder="Start Date" />
                    </Form.Item>

                    <Form.Item label="End Date" name={[field.name, "end"]}>
                      <Input placeholder="End Date" />
                    </Form.Item>

                    <Form.Item
                      label="Description"
                      name={[field.name, "description"]}
                    >
                      <Input placeholder="Description" />
                    </Form.Item>

                    <Form.Item
                      label="Certificate Authority"
                      name={[field.name, "certificate_authority"]}
                    >
                      <Input placeholder="Certificate Authority Name" />
                    </Form.Item>
                  </>
                </Card>
              ))}

              <Button type="dashed" onClick={() => add()} block>
                + Add Awards
              </Button>
            </div>
          )}
        </Form.List>

        <div
          style={{
            width: "100%",
            // border: "2px solid red",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button type="primary" htmlType="submit" style={{ margin: "2rem" }}>
            Submit
          </Button>
        </div>
      </Form>
      <Modal
        style={{ marginTop: "9rem" }}
        title={<div>Submit Created Profile.</div>}
        visible={isModalOpen}
        onOk={postCreatedProfile}
        onCancel={handleCancel}
        // okButtonProps={{
        //   disabled: complianceType?.length ? false : true,
        // }}
      >
        <>Do you want to submit?</>
      </Modal>
    </>
  );
};
export default ProfileCreator;
