import { useState, useEffect, forwardRef } from "react";
import Header from "../Header/Header";
import TabsHomePage from "../Home/TabsHomePage";
import { url } from "../../url";
import axios from "axios";
import { Spin, Switch, Tooltip } from "antd";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  Button,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  MenuItem,
  InputLabel,
  Link,
  Pagination,
  Stack,
  Snackbar,
  Select,
  Box,
} from "@mui/material";

import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const notification = {
  show: false,
  variant: null,
  message: null,
  errorMessage: null,
};

const News = ({ loggedInUserDetails, logoutUser }) => {
  const [readOnlyFlag, setReadOnlyFlag] = useState(false);
  const [show_task_assigned_to, setShow_task_assigned_to] = useState(true);
  const [workDoneCount, setWorkDoneCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPaginateSize, setTotalPaginateSize] = useState(0);
  const [notificationState, setNotificationState] = useState(notification);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [exhaustedDataMessage, setExhaustedDataMessage] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [filterNewsKeyword, setFilterNewsKeyword] = useState("All");

  const onShowTaskAssignedToCheckedChange = (checked) => {
    if (checked) {
      setShow_task_assigned_to(true);
    } else {
      setShow_task_assigned_to(false);
    }
  };

  useEffect(() => {
    getNewsData();
  }, [show_task_assigned_to, page]);

  useEffect(() => {
    getNewsData();
  }, [filterNewsKeyword]);

  const handleResponseChange = (e, index) => {
    setData((prevData) => {
      let tempData = prevData.slice();
      tempData[index]["user_action"] = e.target.value;

      return tempData;
    });
  };

  const getNewsData = async (option) => {
    try {
      setLoading(true);
      let data = await axios.get(
        url.getNewsData +
          `?email=${loggedInUserDetails.email}&role=${
            loggedInUserDetails.role
          }&show_task_assigned_to=${show_task_assigned_to}&page=${
            page - 1
          }&filter=${filterNewsKeyword}`
      );

      if (!data.data.message) {
        for (let iterate = 0; iterate < data.data.data.length; iterate++) {
          data.data.data[iterate]["user_action"] = "";
        }

        setExhaustedDataMessage("");
        setData(
          data.data?.data.map((news) => {
            return { ...news, has_clicked: false };
          })
        );
        setWorkDoneCount(data.data.workDone);
      } else {
        setData([]);
        setExhaustedDataMessage(data.data.message);
      }

      setReadOnlyFlag(data.data.readOnly);
      setTotalPaginateSize(data.data.totalPaginateSize);
    } catch (error) {
      setNotificationState({
        show: true,
        variant: "error",
        message: "Couldn't fetch data",
        errorMessage: error.message,
      });
      setData([]);
      setTotalPaginateSize(0);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitClick = (e, index) => {
    setActiveIndex(index);
    setShowConfirmationModal(true);
  };

  const handleCloseConfirmationModal = (e) => {
    setShowConfirmationModal(false);
  };

  const handleSubmitData = async (e) => {
    markNewsData();
  };

  const markNewsData = async () => {
    try {
      setLoading(true);
      let payload = {
        user_id: loggedInUserDetails._id,
        data_id: data[activeIndex]._id,
        user_action: data[activeIndex]["user_action"],
        link: data[activeIndex].link,
      };
      let updateResponse = await axios.post(url.markNewsData, payload);

      setNotificationState({
        show: true,
        variant: "success",
        message: "Successfully updated",
        errorMessage: null,
      });
    } catch (error) {
      setNotificationState({
        show: true,
        variant: "error",
        message: "Update failed",
        errorMessage: error.message,
      });
    } finally {
      setLoading(false);
      getNewsData();
      handleCloseConfirmationModal();
    }
  };

  const handlePageChange = (e, value) => {
    setPage(value);
  };

  const handleNotificationAlertClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNotificationState({
      show: false,
      variant: null,
      message: null,
      errorMessage: null,
    });
  };

  const dateToString = (crawled_time) => {
    const date = new Date(crawled_time);
    if (date) {
      return date.toString();
    }
    return "";
  };

  const handleFilterOptionChange = (e) => {
    const keyword = e.target.value;
    setFilterNewsKeyword(keyword);
  };

  return (
    <div>
      <Header
        loggedInUserDetails={loggedInUserDetails}
        logoutUser={logoutUser}
      />
      {readOnlyFlag ? (
        <h6 className="text-center mt-5">No tasks assigned | Read only mode</h6>
      ) : null}
      {loggedInUserDetails.role === "admin" ? (
        <h6 className="text-center">
          <Switch
            checkedChildren="Show all"
            unCheckedChildren="Show only assigned"
            className="mx-5"
            defaultChecked
            onChange={onShowTaskAssignedToCheckedChange}
          />
        </h6>
      ) : null}
      <div className="pe-5 ps-5">
        <TabsHomePage />
      </div>
      <div className="pe-5 ps-5">
        <div className="mt-4 mb-4 total-data-container">
          <div className="row h-100 w-100">
            <div className="m-3 d-flex align-items-center justify-content-between">
              <Box sx={{ width: 300 }} size="small">
                <FormControl sx={{ width: 300 }} size="small">
                  <InputLabel id="demo-select-medium-label">
                    Filter News
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={filterNewsKeyword}
                    label="Filter News"
                    onChange={(e) => handleFilterOptionChange(e)}
                  >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="CXO Departure">CXO Departure</MenuItem>
                    <MenuItem value="CXO Appointed">CXO Appointed</MenuItem>
                    <MenuItem value="Mergers and Acquisitions">
                      Mergers and Acquisitions
                    </MenuItem>
                    <MenuItem value="Funding">Funding</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {!readOnlyFlag ? (
                  <p style={{ paddingRight: "18rem" }}>
                    Work done for today in CXO News: {workDoneCount}
                  </p>
                ) : null}
              </Box>
            </div>

            {!loading ? (
              <div className="">
                {data.length === 0 ? (
                  <p className="text-center">No data to show</p>
                ) : exhaustedDataMessage.length > 0 ? (
                  <p className="text-center">{exhaustedDataMessage}</p>
                ) : (
                  <Stack
                    spacing={4}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <b>Row</b>
                            </TableCell>
                            <TableCell>
                              <b>Title</b>
                            </TableCell>
                            <TableCell>
                              <b>Published</b>
                            </TableCell>
                            <TableCell>
                              <b>Link</b>
                            </TableCell>
                            <TableCell>
                              <b>Status</b>
                            </TableCell>
                            <TableCell>
                              <b>Action</b>
                            </TableCell>
                            {loggedInUserDetails.role === "admin" ? (
                              <TableCell>
                                <b>Assigned To</b>
                              </TableCell>
                            ) : null}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.title}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {dateToString(row.crawled_time)}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  maxWidth: "20rem",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <Link
                                  href={row.link}
                                  target="_blank"
                                  onClick={(e) => {
                                    setData((prevData) => {
                                      return prevData.map(
                                        (news, iterateIndex) => {
                                          if (iterateIndex === index) {
                                            return {
                                              ...news,
                                              has_clicked: true,
                                            };
                                          }
                                          return news;
                                        }
                                      );
                                    });
                                  }}
                                >
                                  {row.link}
                                </Link>
                              </TableCell>
                              <TableCell>
                                <FormControl sx={{ width: 300 }} size="small">
                                  <InputLabel id="demo-select-small-label">
                                    Select response
                                  </InputLabel>
                                  <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={row?.user_action || ""}
                                    label={"Select response"}
                                    onChange={(e) =>
                                      handleResponseChange(e, index)
                                    }
                                  >
                                    <MenuItem value="Done">Done</MenuItem>
                                    <MenuItem value="Error">Error</MenuItem>
                                    <MenuItem value="No action">
                                      No action
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  disabled={
                                    !data[index]?.["user_action"].length ||
                                    readOnlyFlag ||
                                    !data[index]?.has_clicked
                                  }
                                  onClick={(e) => handleSubmitClick(e, index)}
                                >
                                  Submit
                                </Button>
                              </TableCell>
                              {loggedInUserDetails.role === "admin" ? (
                                <TableCell>
                                  {row?.task_assigned_to || ""}
                                </TableCell>
                              ) : null}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Pagination
                      count={Math.ceil(totalPaginateSize / 20)}
                      page={page}
                      onChange={handlePageChange}
                    />
                  </Stack>
                )}
              </div>
            ) : (
              <Spin className="text-center" tip="Loading..." />
            )}
          </div>
        </div>
        <Dialog
          open={showConfirmationModal}
          onClose={handleCloseConfirmationModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm your changes"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Mark {data[activeIndex]?.title} as{" "}
              <em>
                <b>{data[activeIndex]?.["user_action"]}</b>
              </em>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmationModal}>Close</Button>
            <Button onClick={handleSubmitData}>Submit</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={notificationState?.show || false}
          autoHideDuration={6000}
          onClose={handleNotificationAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleNotificationAlertClose}
            severity={notificationState?.variant || "success"}
            sx={{ width: "100%" }}
          >
            {notificationState?.message || ""} |{" "}
            {notificationState?.errorMessage || ""}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default News;
