import React, { useMemo } from "react";
import findemLogo from "../../assets/findem-logo-small-squircle.png";
import "./header.styles.css";
import { useNavigate } from "react-router-dom";


import {
  DownOutlined
} from "@ant-design/icons";
import {Layout, Menu, Image, Row, Col, Dropdown, Space, Typography } from "antd";
const { Header } = Layout;


const HeaderComponent = ({loggedInUserDetails,logoutUser}) => {
  const navigate = useNavigate();

  const menuItems = useMemo(() => {
    if (!loggedInUserDetails) {
      return [];
    }

    if (loggedInUserDetails?.role === 'admin') {
      return [
        {
          key:"reports",
          label:"Reports",
          onClick: () => {navigate("/reports")}
        },
        {
          key:"userGroups",
          label:"User Groups",
          onClick: ()=>{navigate("/user-groups")}
        },
        {
          key:"Logout",
          label:"Logout",
          onClick: () => logoutUser()
        },
      ]
    } else {
      return [{
        key:"Logout",
        label:"Logout",
        onClick: () => logoutUser()
      }];
    }
    
  }, [loggedInUserDetails])
  
  return (<div style={{display: "flex", justifyContent: "space-between", margin: "15px 47px 20px 47px", alignItems: "center"}}>
    <div>
      <img src={findemLogo} alt="Findem Logo"  width="32px" height="32px" style={{display: "inline", marginRight: "8px"}}/>
      <span style={{fontSize: "15px"}}>
        <span style={{ color: "#4798FE", fontWeight: "600" }}>FINDEM</span> DATA
        RESEARCH APPLICATION
      </span>
    </div> 
    <div>
      <Dropdown overlay={(
        <Menu items={menuItems} />
        )}>
        <Typography.Link>
          <Space>
            {`Hello ${loggedInUserDetails.name}`}
            <DownOutlined />
          </Space>
        </Typography.Link>
      </Dropdown>
    </div>
  </div>)
};

export default HeaderComponent;
