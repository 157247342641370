import { useMemo, useState } from 'react';
import {
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    FormControlLabel, 
    FormGroup, 
    Switch,
    Select,
    OutlinedInput,
    MenuItem,
    useTheme,
    Box
} from '@mui/material';
import { default as ReactSelect } from 'react-select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const sources = [
    "copilot_customer",
    "copilot_preprocessed_searches",
    "normal_icp",
    "special_icp"
];

function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
}

export const FiltersModal = ({
    loggedInUserDetails,
    filtersValues,
    isFiltersModalOpen,
    handleCloseFiltersModal,
    customersData,
    handleApply
}) => {
    const theme = useTheme();

    const [ignoreAssignedTo, setIgnoreAssignedTo] = useState(filtersValues.ignoreAssignedTo);
    const [isCustomerIcp, setIsCustomerIcp] = useState(filtersValues.isCustomerIcp);
    const [selectedSources, setSelectedSources] = useState(filtersValues.selectedSources);
    const [selectedCompanyDetails, setSelectedCompanyDetails] = useState(filtersValues.selectedCompanyDetails);
    const [showOnlyProfilesWithAlternativeSuggestions, setShowOnlyProfilesWithAlternativeSuggestions] = useState(filtersValues.showOnlyProfilesWithAlternativeSuggestions);

    const companySelectOptions = useMemo(() => {
        const companySelectOptions = [];

        for (let accountId in customersData) {
            const { users, company_name } = customersData[accountId];

            const option = {
                users,
                company_name,
                accountId,
                value: users,
                label: company_name
            }

            companySelectOptions.push(option);
        }

        return companySelectOptions;
    }, [customersData]);

    const handleIgnoreAssignedToChange = (event) => {
        setIgnoreAssignedTo(event.target.checked);
    }

    const handleIsCustomerIcpChange = (event) => {
        setIsCustomerIcp(event.target.checked);
    }

    const handleAltLinkOptions = (event) => {
        setShowOnlyProfilesWithAlternativeSuggestions(event.target.checked);
    }

    const handleSourceChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelectedSources(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleCompanySelectChange = (newValue, actionMeta) => {
        // if (actionMeta.action === "select-option") {
            setSelectedCompanyDetails(newValue);
            console.log("Company value changes: ", newValue);
        // }
    }

    const handleApplyOnClick = () => {
        handleApply({ignoreAssignedTo, isCustomerIcp, selectedSources, selectedCompanyDetails, showOnlyProfilesWithAlternativeSuggestions});
    }

    return (
        <Dialog
            open={isFiltersModalOpen}
            onClose={handleCloseFiltersModal}
            aria-describedby='Filters Options Modal'
            fullWidth={true}
            maxWidth="md"
            scroll={'body'}
        >
            <DialogTitle>Click on Apply to see the changes</DialogTitle>
            <DialogContent>
                <Box>
                    <FormGroup>
                        {loggedInUserDetails.role === "admin" && 
                            <FormControlLabel 
                                control={<Switch
                                        checked={ignoreAssignedTo}
                                        onChange={handleIgnoreAssignedToChange}
                                        inputProps={{ 'aria-label': 'ignore-assigned-to-checkbox' }}
                                    />}
                                label={ignoreAssignedTo ? "Show all profiles" : "Show only assigned"}
                            />
                        }
                        <FormControlLabel 
                            control={<Switch
                                    checked={isCustomerIcp}
                                    onChange={handleIsCustomerIcpChange}
                                    inputProps={{ 'aria-label': 'is-customer-icp-checkbox' }}
                                />}
                            label={isCustomerIcp ? "Customer ICP" : "Internal ICP"}
                        />
                        <FormGroup>
                            <FormControlLabel 
                                control={<Switch
                                        checked={showOnlyProfilesWithAlternativeSuggestions}
                                        onChange={handleAltLinkOptions}
                                        inputProps={{ 'aria-label': 'alt-link-options-checkbox' }}
                                    />}
                                label={"Show only profiles with alternative suggestions"}
                            />
                        </FormGroup>
                    </FormGroup>
                    <Select
                        sx={{width: "100%", mt: 1}}
                        multiple
                        displayEmpty
                        value={selectedSources}
                        onChange={handleSourceChange}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                            return <em>All Sources</em>;
                            }
                
                            return selected.join(', ');
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ 'aria-label': 'select-sources' }}
                        >
                            <MenuItem disabled value="">
                                <em>All Sources</em>
                            </MenuItem>
                            {sources.map((source) => (
                                <MenuItem
                                    key={source}
                                    value={source}
                                    style={getStyles(source, selectedSources, theme)}
                                >
                                    {source}
                                </MenuItem>
                            ))}
                    </Select>
                    <Box sx={{ mt: 2 }}>
                        <ReactSelect
                            isClearable={true}
                            value={selectedCompanyDetails}
                            options={companySelectOptions}
                            onChange={handleCompanySelectChange}
                            placeholder='Select a company'
                            menuPortalTarget={document.body} 
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}

                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseFiltersModal}>Cancel</Button>
                <Button onClick={handleApplyOnClick}>Apply</Button>
            </DialogActions>
        </Dialog>
    );
}