import { Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { url } from "../../../url";
import Loading from "./Loading";
import RightPane from "./RightPane";
import NorthWestIcon from "@mui/icons-material/NorthWest";
import axios from "axios";
import validator from "validator";
import GenericSnackbar from "./GenericSnackbar";

// {
//   "_id": "65a07d7c9d0b43a3caa2d6c2",
//   "profile_id": "pub_5c7bae970cadfda94cd1e462",
//   "account_id": "7of9oso8zkavwmnwnce1y",
//   "channel": "ats",
//   "icp_id": "63ff9d606bfe52b36b50ccc0",
//   "priority": "low",
//   "sandbox_name": "findem_ai_public_profiles",
//   "verification_state": {
//       "status": "unverified"
//   },
//   "verification_state_ts": 1705101568060,
//   "icp_name": "Test Assign 01",
//   "icp_search_link": "https://app-next.findem.ai/searches/63ff9d606bfe52b36b50ccc0?joid=625ee19aadb3d7d7c1ef7202&redirect_joid=625ee19aadb3d7d7c1ef7202",
//   "customer_name": "Findem",
//   "ep_link": "https://app-next.findem.ai/profiles/pub_5c7bae970cadfda94cd1e462?state=/searches/63ff9d606bfe52b36b50ccc0&icpid=63ff9d606bfe52b36b50ccc0&joid=63ff9d606bfe52b36b50ccc0&aId=7of9oso8zkavwmnwnce1y"
// }

const RightPaneContainer = ({
  loggedInUserDetails,
  data,
  selectedIndex,
  status,
  handleStatusChange,
  handleOpenSnackbar,
  handleCloseSnackbar,
  fetchData,
  setEmailsToDisplay,
  emailsToDisplay,
  phoneNumbersToDisplay,
  setPhoneNumbersToDisplay,
  contactInfoData,
  setContactInfoData,
  removedEmailsToDisplay,
  setRemovedEmailsToDisplay,
  directEmailsFromATS,
  setDirectEmailsFromATS,
}) => {
  console.log("DEBUG: ", data);

  const checkboxEmailFill = (data, contactInfoData) => {
    let returnObject = {};
    if (data["sandbox_name"] != null) {
      // in sandbox show all

      contactInfoData["public_emails"]?.forEach((email) => {
        returnObject[email] = { type: "public_emails", checked: false };
      });
      contactInfoData["global_ats_emails"]?.forEach((email) => {
        returnObject[email] = { type: "global_ats_emails", checked: false };
      });
      contactInfoData["golden_emails"]?.forEach((email) => {
        returnObject[email] = { type: "golden_emails", checked: false };
      });
      contactInfoData["sandbox_ats_emails"]?.forEach((email) => {
        returnObject[email] = { type: "sandbox_ats_emails", checked: true };
      });
      contactInfoData["sandbox_emails"]?.forEach((email) => {
        returnObject[email] = { type: "sandbox_emails", checked: true };
      });
    } else {
      contactInfoData["global_ats_emails"]?.forEach((email) => {
        returnObject[email] = { type: "global_ats_emails", checked: true };
      });
      contactInfoData["golden_emails"]?.forEach((email) => {
        returnObject[email] = { type: "golden_emails", checked: false };
      });
      contactInfoData["public_emails"]?.forEach((email) => {
        returnObject[email] = { type: "public_emails", checked: true };
      });
    }

    console.log("in checkbox email fill : ", returnObject);
    return returnObject;
  };

  const checkboxPhoneFill = (data, contactInfoData) => {
    let returnObject = {};

    if (data["sandbox_name"] != null) {
      contactInfoData["global_phones"]?.forEach((phoneNumber) => {
        returnObject[phoneNumber] = { type: "global_phones", checked: false };
      });
      contactInfoData["sandbox_phones"]?.forEach((phoneNumber) => {
        returnObject[phoneNumber] = { type: "sandbox_phones", checked: true };
      });
    } else {
      contactInfoData["global_phones"]?.forEach((phoneNumber) => {
        returnObject[phoneNumber] = { type: "global_phones", checked: true };
      });
    }

    return returnObject;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [addedEmails, setAddedEmails] = useState([]);
  const [showInvalidEmailError, setShowInvalidEmailError] = useState(false);
  const [inputPhoneNumber, setInputPhoneNumber] = useState("");
  const [addedPhoneNumbers, setAddedPhoneNumbers] = useState([]);
  const [showInvalidPhoneNumberError, setShowInvalidPhoneNumberError] =
    useState(false);
  const [emailInputError, setEmailInputError] = useState(false);
  const [phoneInputError, setPhoneInputError] = useState(false);

  const [showUnsavedContactInfoWarning, setShowUnsavedContactInfoWarning] =
    useState(false);

  // Modals states
  const [
    showConfirmationContactInfoModal,
    setShowConfirmationContactInfoModal,
  ] = useState(false);

  const handleShowConfirmContactInfoModal = () => {
    setShowConfirmationContactInfoModal(true);
  };

  const handleCloseConfirmContactInfoModal = () => {
    setShowConfirmationContactInfoModal(false);
  };

  const handleOnChangeEmailInput = (e) => {
    if (e.target.value.length === 0) {
      setEmailInputError(false);
    }
    setInputEmail(e.target.value);
  };

  const handleAddInputEmail = (e) => {
    if (
      contactInfoData["sandbox_removed_emails"].find(
        (email) => email.toLowerCase() === inputEmail.toLowerCase()
      ) ||
      contactInfoData["public_removed_emails"].find(
        (email) => email.toLowerCase() === inputEmail.toLowerCase()
      )
    ) {
      handleOpenSnackbar("Email already exists in removed emails", "error");
    } else if (validator.isEmail(inputEmail)) {
      setEmailsToDisplay((prevState) => {
        return {
          ...prevState,
          [inputEmail]: { ...prevState[inputEmail], checked: true },
        };
      });
      setInputEmail("");
      setShowUnsavedContactInfoWarning(true);
      setEmailInputError(false);
    } else {
      setEmailInputError(true);
    }
  };

  const handleAddInputPhoneNumber = () => {
    if (validator.isMobilePhone(inputPhoneNumber)) {
      setPhoneNumbersToDisplay((prevState) => {
        return {
          ...prevState,
          [inputPhoneNumber]: { ...prevState[inputPhoneNumber], checked: true },
        };
      });
      setInputPhoneNumber("");
      setPhoneInputError(false);
      setShowUnsavedContactInfoWarning(true);
    } else {
      setPhoneInputError(true);
    }
  };

  const handleEmailDisplayChange = (event) => {
    setEmailsToDisplay((prevState) => {
      return {
        ...prevState,
        [event.target.name]: {
          ...prevState[event.target.name],
          checked: event.target.checked,
        },
      };
    });
  };

  const handlePhoneNumberDisplayChange = (event) => {
    setPhoneNumbersToDisplay((prevState) => {
      console.log("handle phone numbers display change", event.target);
      return {
        ...prevState,
        [event.target.name]: {
          ...prevState[event.target.name],
          checked: event.target.checked,
        },
      };
    });
  };

  /*
    "public_emails": [
      "0.gurpreet@gmail.com",
      "m19.gurpreet@gmail.com",
      "gurpreet.matharu@alcatel-lucent.com",
      "gurpreet.matharu@nokia.com",
      "m19gurpreet@gmail.com"
  ],
  "linkedins": [
      "https://linkedin.com/in/gurpreetmatharu"
  ],
  "sandbox_emails": [],
  "golden_emails": []
}
*/
  // This useEffect is not necessary
  useEffect(() => {
    if (selectedIndex != null) {
      getContactInfo();
    }
  }, [selectedIndex]);

  const fetchContactInfoApiWrapper = async (data) => {
    return await axios.post(url.fetchContactInfoByPrids, {
      prids: [data.profile_id],
      sandbox_namespace: data?.sandbox_name || null,
      owner_id: data.owner_id,
    });
  };
  const getContactInfo = async () => {
    console.log("*******");
    try {
      setIsLoading(true);

      const contact_response = await fetchContactInfoApiWrapper(data);
      console.log("result: ", contact_response.data.data);
      const contact_info = contact_response.data.data;
      setContactInfoData(contact_info);
      setEmailsToDisplay(() => {
        let returnObject = {};
        if (data["sandbox_name"] != null) {
          // in sandbox show all
          // returnObject["test123@gmail.com"] = {
          //   type: "sandbox_emails",
          //   checked: true,
          // };
          // returnObject["test123@gmail.com"] = {
          //   type: "sandbox_emails",
          //   checked: false,
          // };

          contact_info["public_emails"]?.forEach((email) => {
            returnObject[email] = {
              type: "public_emails",
              checked: false,
            };
          });
          contact_info["global_ats_emails"]?.forEach((email) => {
            returnObject[email] = { type: "global_ats_emails", checked: false };
          });
          contact_info["golden_emails"]?.forEach((email) => {
            returnObject[email] = { type: "golden_emails", checked: false };
          });
          contact_info["sandbox_ats_emails"]?.forEach((email) => {
            returnObject[email] = { type: "sandbox_ats_emails", checked: true };
          });
          contact_info["sandbox_emails"]?.forEach((email) => {
            returnObject[email] = { type: "sandbox_emails", checked: true };
          });
        } else {
          contact_info["global_ats_emails"]?.forEach((email) => {
            returnObject[email] = { type: "global_ats_emails", checked: true };
          });
          contact_info["golden_emails"]?.forEach((email) => {
            returnObject[email] = { type: "golden_emails", checked: false };
          });
          contact_info["public_emails"]?.forEach((email) => {
            returnObject[email] = { type: "public_emails", checked: true };
          });
        }

        console.log("Inside checkboxemailfill : ", returnObject);
        return returnObject;
      });
      setRemovedEmailsToDisplay(() => {
        let returnObject = {};
        if (data["sandbox_name"] != null) {
          // in sandbox show all
          contact_info["sandbox_removed_emails"]?.forEach((email) => {
            returnObject[email] = {
              type: "sandbox_removed_emails",
              checked: false,
              disabled: true,
            };
          });
          contact_info["public_removed_emails"]?.forEach((email) => {
            returnObject[email] = {
              type: "public_removed_emails",
              checked: false,
              disabled: true,
            };
          });
        } else {
          contact_info["public_removed_emails"]?.forEach((email) => {
            returnObject[email] = {
              type: "public_removed_emails",
              checked: false,
              disabled: true,
            };
          });
        }

        return returnObject;
      });
      setDirectEmailsFromATS(() => {
        let returnObject = {};
        contact_info["ats_info"]?.forEach((ats_info_obj) => {
          ats_info_obj?.email_address?.forEach((email) => {
            returnObject[email] = {
              type: "direct_emails_from_ats",
              checked: false,
              disabled: true,
            };
          });
        });

        return returnObject;
      });
      setPhoneNumbersToDisplay(() => {
        let returnObject = {};

        if (data["sandbox_name"] != null) {
          contact_info["global_phones"]?.forEach((phoneNumber) => {
            returnObject[phoneNumber] = {
              type: "global_phones",
              checked: false,
            };
          });
          contact_info["sandbox_phones"]?.forEach((phoneNumber) => {
            returnObject[phoneNumber] = {
              type: "sandbox_phones",
              checked: true,
            };
          });
        } else {
          contact_info["global_phones"]?.forEach((phoneNumber) => {
            returnObject[phoneNumber] = {
              type: "global_phones",
              checked: true,
            };
          });
        }

        return returnObject;
      });
      setShowUnsavedContactInfoWarning(false);
    } catch (error) {
      handleOpenSnackbar("Error failed to get contact info", "error");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnChangeEmailPhoneNumberInput = (e) => {
    if (e.target.value.length === 0) {
      setPhoneInputError(false);
    }
    setInputPhoneNumber(e.target.value);
  };

  const handleSaveContactInfo = async (event) => {
    console.log("emailObject: ", emailsToDisplay);
    const checked_emails_to_display = [];
    for (let email in emailsToDisplay) {
      if (emailsToDisplay[email]["checked"]) {
        checked_emails_to_display.push(email);
      }
    }
    console.log("email object after filter: ", checked_emails_to_display);
    const checked_phone_numbers_to_display = [];
    console.log("checked***", phoneNumbersToDisplay);
    for (let phoneNumber in phoneNumbersToDisplay) {
      if (phoneNumbersToDisplay[phoneNumber]["checked"]) {
        checked_phone_numbers_to_display.push(phoneNumber);
      }
    }

    const existing_contact_info_data = contactInfoData;

    try {
      setIsLoading(true);

      const profile_id = data["profile_id"];
      const owner_id = data["owner_id"];
      const icp_search_link = data["icp_search_link"];
      const customer_name = data["customer_name"];
      const ep_link = data["ep_link"];
      const full_name = data["full_name"];
      const last_ingested_at = data["last_ingested_at"];
      const account_id = data["account_id"];
      const account_ids = data["account_ids"];
      const channel = data["channel"];
      const priority = data["priority"];
      const sandbox_name = data["sandbox_name"];
      const prev_verification_state_object = data["verification_state"];
      const verification_state_ts =
        prev_verification_state_object["verification_state_ts"];
      const prev_verification_status = prev_verification_state_object["status"];
      const prev_verification_note =
        prev_verification_state_object["notes"] ||
        prev_verification_state_object["reason"];
      const icp_id = data["icp_id"];
      const updated_at = data["updated_at"];

      console.log("Obj:", {
        profile_id,
        sandbox_name,
        added_emails: checked_emails_to_display,
        added_phone_numbers: checked_phone_numbers_to_display,
        existing_contact_info_data,
        owner_id,
      });
      let auditLogData = {
        eventName: "contact_info_rt_copilot_search",
        eventType: "save",
        userId: loggedInUserDetails._id,
        eventMetaData: {
          candidateId: profile_id,
          candidateName: full_name,
          customerName: customer_name ? customer_name : "not specified",
          shortlistLink: icp_search_link,
          ownerId: owner_id,
          epLink: ep_link,
          lastIngestedAt: String(last_ingested_at),
          accountId: account_id,
          accountIds: account_ids,
          channel: channel,
          priority: priority,
          sandboxName: sandbox_name,
          icpId: icp_id,
          profileUpdatedAt: updated_at,
        },
        oldRecord: {
          publicEmails: contactInfoData.public_emails,
          linkedins: contactInfoData.linkedins,
          sandboxEmails: contactInfoData.sandbox_emails,
          goldenEmails: contactInfoData.golden_emails,
          sandboxPhones: contactInfoData.sandbox_phones,
          globalPhones: contactInfoData.global_phones,
          sandboxRemovedEmails: contactInfoData.sandbox_removed_emails,
          publicRemovedEmails: contactInfoData.public_removed_emails,
          sandboxAtsEmails: contactInfoData.sandbox_ats_emails,
          globalAtsEmails: contactInfoData.global_ats_emails,
          atsInfo: contactInfoData.ats_info,
          verificationStateTs: String(verification_state_ts),
          status: prev_verification_status,
          comment: prev_verification_note,
        },
        newRecord: {
          addedEmail: checked_emails_to_display,
          addedPhone: checked_phone_numbers_to_display,
        },
      };

      const response = await axios.post(url.saveContactInfo, {
        profile_id,
        sandbox_name,
        added_emails: checked_emails_to_display,
        added_phone_numbers: checked_phone_numbers_to_display,
        existing_contact_info_data,
        owner_id,
        audit_logs: auditLogData,
      });
      getContactInfo();
      setShowUnsavedContactInfoWarning(false);
      handleOpenSnackbar("Successfully added contact info", "success");
    } catch (error) {
      console.log(error);
      handleOpenSnackbar("Failed to add contact info", "error");
    } finally {
      setIsLoading(false);
      handleCloseConfirmContactInfoModal();
      // dont fetch main data here, instead refresh contact info only
      // fetchData();
    }
  };

  return (
    <Paper
      style={{
        marginLeft: "5px",
        flex: 1,
      }}
      variant="outlined"
    >
      {selectedIndex === null ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "67vh",
          }}
        >
          <Typography variant="h6">
            <NorthWestIcon /> Select left pane to display the details
          </Typography>
        </div>
      ) : isLoading ? (
        <Loading />
      ) : (
        <RightPane
          data={data}
          handleStatusChange={handleStatusChange}
          status={status}
          inputEmail={inputEmail}
          setInputEmail={setInputEmail}
          addedEmails={addedEmails}
          setAddedEmails={setAddedEmails}
          showInvalidEmailError={showInvalidEmailError}
          setShowInvalidEmailError={setShowInvalidEmailError}
          inputPhoneNumber={inputPhoneNumber}
          setInputPhoneNumber={setInputPhoneNumber}
          addedPhoneNumbers={addedPhoneNumbers}
          setAddedPhoneNumbers={setAddedPhoneNumbers}
          showInvalidPhoneNumberError={showInvalidPhoneNumberError}
          setShowInvalidPhoneNumberError={setShowInvalidPhoneNumberError}
          contactInfoData={contactInfoData}
          handleAddInputEmail={handleAddInputEmail}
          handleEmailDisplayChange={handleEmailDisplayChange}
          emailsToDisplay={emailsToDisplay}
          directEmailsFromATS={directEmailsFromATS}
          emailInputError={emailInputError}
          handleAddInputPhoneNumber={handleAddInputPhoneNumber}
          phoneInputError={phoneInputError}
          phoneNumbersToDisplay={phoneNumbersToDisplay}
          handleOnChangeEmailInput={handleOnChangeEmailInput}
          handleOnChangeEmailPhoneNumberInput={
            handleOnChangeEmailPhoneNumberInput
          }
          handlePhoneNumberDisplayChange={handlePhoneNumberDisplayChange}
          handleSaveContactInfo={handleSaveContactInfo}
          showConfirmationContactInfoModal={showConfirmationContactInfoModal}
          handleCloseConfirmContactInfoModal={
            handleCloseConfirmContactInfoModal
          }
          handleShowConfirmContactInfoModal={handleShowConfirmContactInfoModal}
          showUnsavedContactInfoWarning={showUnsavedContactInfoWarning}
          removedEmailsToDisplay={removedEmailsToDisplay}
        />
      )}
    </Paper>
  );
};

export default RightPaneContainer;
