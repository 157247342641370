import React, { useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";

import axios from "axios";
import { url } from "../../url";
import PopulatedFormToEdit from "./PopulatedFormToEdit";

const ProfileEditor = ({ loggedInUserDetails }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [refID, setRefID] = useState("");
  const [data, setData] = useState({});



  const fetchProfile = async (refID) => {
    setIsLoading(true);
    try {
      const payload = {
        refs: [refID],
      };

      const response = await axios.post(url.fetchPublicProfile, payload);

      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        setData(response.data.data[0]);
      } else {
        throw new Error("Profile data is empty or not available.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error(
        error.response?.data?.message ||
          "Failed to fetch profile data. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const resetData = () => {
    setData({});
    setIsLoading(false);
    setRefID("");
  };

  const handleRefIdChange = (refId) => {
    setRefID(refId.ref_id);
    fetchProfile(refId.ref_id)
  };

  const onFinishFailed = (err) => {
    console.log(err);
  };

  return (
    <div>
      {!Object.keys(data).length ? (
        <div>
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 900,
              width: "800px",
              display: "flex",
              flexDirection: "column",
              marginTop: "3rem",
              padding: "4rem",
              borderRadius: "10px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
            onFinish={handleRefIdChange}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Search Profile By Ref ID"
              name="ref_id"
              rules={[
                {
                  required: true,
                  message: "Please enter your Ref!",
                },
              ]}
            >
              <Input style={{ width: "41.5rem" }} placeholder="Enter Ref" />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 10,
                span: 16,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "1rem" }}
                loading={isLoading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <PopulatedFormToEdit
          loggedInUserDetails={loggedInUserDetails}
          refID={refID}
          setIsLoading={setIsLoading}
          dataProp={data}
          setData={setData}
          resetData={resetData}
        />
      )}
    </div>
  );
};
export default ProfileEditor;
