import dayjs from "dayjs";

export const emptyProfile = {
    created_at: dayjs(new Date()),
    updated_at: dayjs(new Date()),
    last_ingested_at: dayjs(new Date()),
    int_updated_at: dayjs(),
    candidate: {
        angel: "",
        artstation: [],
        behance: [],
        codechef: [],
        codeforces: [],
        crunchbase: [],
        doximity: [],
        dribbble: [],
        email: "",
        github: [],
        stackoverflow: [],
        ethnicity: [],
        emails: [],
        other_contacts: [],
        candidate_tags: [],
        kaggle: [],
        leetcode: [],
        nfx_signal: "",
        phone: "",
        quora: [],
        research_gate: "",
        rgate: "",
        scholar: "",
        twitter: [],
        type: "",
        connections: [],
        full_name: "Prateek",
        sname: "",
        title: "",
        about_me: "",
        address: "",
        location_raw: "",
        linkedin: "",
        gender: "",
        location: {
            city: "",
            state: "",
            country: "India",
        },
        lang: "",
        dob: "",
        num_connections: 0,
    },
    skills: [],
    workexp: [
        {
            skills: [],
            exp_type: [],
            committee_names: [],
            committee_roles: [],
            exec_profile: [],
            projects: [
                {
                    project_type: ["Industry"],
                    role_and_group: {
                        title: "Software Development Engineer",
                        capacity: "Individual Contributor",
                    },
                    misc: {
                        contributors: [],
                    },
                    products_used: [],
                    description: "Skills: Node.js",
                },
            ],
            company: {
                name: "MountBlue Technologies",
                company_id: "6023504dde9e51864cbb70b2",
                lnkd_company_id: "mountblue",
                lnkd_company_src_id: "13321129",
                location: "Remote",
            },
            duration: {
                start_date: dayjs(new Date()),
                end_date: null,
                to_present: true,
            },
        },
    ],
    ext_refs: [],
    awards: [
        {
            name: "",
            date: "",
            description: "",
            issuer: "",
        },
    ],
    certifications: [
        {
            title: "",
            start: "",
            end: "",
            description: "",
            certificate_authority: "",
        },
    ],
    educations: [
        {
            education_details: {
                degree: ["Masters in Business Administration"],
                major: ["Business Administration"],
                skills: [],
                projects: [],
                courses: [],
                description: "B.S. Commerce and Engineering 1980\nM.B.A Manpower Management 1985",
            },
            school_info: {
                name: "Drexel University",
                lnkd_school_src_id: "19245",
                school_id: "5b0c4aa73200621bdb3aedca",
            },
            misc: [],
            duration: {
                start_date: dayjs(),
                end_date: dayjs(),
            },
        },
    ],
    patents: [
        {
            title: "",
            number: "",
            date: "",
            description: "",
            url: "",
        },
    ],
    positions: [
        {
            title: "",
            companyName: "",
            companyDomain: "",
            roles: [
                {
                    title: "",
                    location: "",
                    date1: "",
                    date2: "",
                },
            ],
        },
    ],
    current_position: [{ current_position: "" }],
    profile: [
        { name: "" },
        { location: "" },
        { city: "" },
        { state: "" },
        { country: "" },
        { imageUrl: "" },
        { gender: "" },
        { ethnicity: [""] },
    ],
    projects: [
        {
            name: "",
            description: "",
            start: "",
            end: "",
        },
    ],
    publications: [
        {
            title: "",
            publisher: "",
            date: "",
            summary: "",
        },
    ],
    manual_update_history: [],
    dedupe_history: [],
};
export const sources = [
    "Behance",
    "Artstation",
    "Dribbble",
    "LinkedIn",
    "Twitter",
    "Facebook",
    "Doximity",
    "GitHub",
    "StackOverflow",
    "Instagram",
    "Kaggle",
    "LeetCode",
    "CodeForces",
    "CodeChef",
    "Quora",
    "Scholar",
];
