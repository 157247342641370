import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    IconButton,
    Input,
    List,
    ListItem,
    ListSubheader,
    Paper,
    TextField,
} from "@mui/material";
import HeaderComponent from "../Header/Header";
import TabsHomePage from "../Home/TabsHomePage";
import { useEffect, useState } from "react";
import { message } from "antd";
import axios from "axios";
import { url } from "../../url";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import GroupIcon from "@mui/icons-material/Group";
import InputCard from "./InputCard";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import UserGroup from "./UserGroup";
import { useNavigate } from "react-router-dom";
const UserGoups = ({ loggedInUserDetails, logoutUser }) => {
    const [userGroups, setUserGroups] = useState({ data: [], isLoading: true });
    const navigate = useNavigate();

    const fetchUserGroups = async () => {
        try {
            const response = await axios.get(url.getUserGroups);
            setUserGroups({ data: response?.data?.data, isLoading: false });
        } catch (error) {
            console.log("Failed to get user group:", error);
            message.error("Failed to get user groups!");
            setUserGroups({ ...userGroups, isLoading: false });
        }
    };
    const addUserGroup = async (newGroupName) => {
        try {
            const response = await axios.post(url.crudUserGroup, {
                groupName: newGroupName,
            });
            // console.log(response);
            setUserGroups({ data: [...userGroups.data, response?.data?.data], isLoading: false });
        } catch (error) {
            console.log("Failed to add new group:", error);
            if (error?.response?.status == 409) {
                message.warning("Group name already exits!");
                return;
            }
            message.error("Failed to add new group!");
        }
    };

    useEffect(() => {
        // navigate to root page if user is unauthorized
        if (loggedInUserDetails.role != "admin") {
            navigate("/");
            return;
        }
        fetchUserGroups();
    }, []);
    return (
        <>
            <HeaderComponent loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser} />
            <div className="pe-5 ps-5">
                <TabsHomePage />
            </div>
            <Paper elevation={0} sx={{ padding: "3rem", minHeight: "80vh", color: "#172B4D" }}>
                <Box sx={{ display: "flex", gap: "1rem", alignItems: "flex-start", overflowY: "auto", width: "100%" }}>
                    {userGroups?.data?.map((group) => {
                        return <UserGroup userGroups={userGroups} setUserGroups={setUserGroups} group={group} />;
                    })}
                    <InputCard label={"Add group"} inputLabel={"Group Name"} onSubmit={addUserGroup} />
                </Box>
            </Paper>
        </>
    );
};

export default UserGoups;
