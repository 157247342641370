import React, { useEffect, useState, useRef, useMemo } from "react";
import Header from "../Header/Header";
import TabsHomePage from "../Home/TabsHomePage";
import axios from "axios";
import Papa from "papaparse";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload, Spin } from "antd";
import { Button as MuiButton } from "@mui/material";
import { url } from "../../url";
import GenericCreatable from "../CopilotSearches/GenericCreatable";
import { Paper, Stack, Typography } from "@mui/material";
import GenericSnackbar from "../CopilotSearches/RTCopilotSearches/GenericSnackbar";

const AssignWork = ({ loggedInUserDetails, logoutUser }) => {
  const [csvFile, setCsvFile] = useState();
  const [assigningWorkLoader, setAssigningWorkLoader] = useState(false);
  const [resultFromUploadCsv, setResultFromUploadCsv] = useState("");
  const [errorFromUploadCsv, setErrorFromUploadCsv] = useState("");

  return (
    <div>
      <Header
        loggedInUserDetails={loggedInUserDetails}
        logoutUser={logoutUser}
      />
      <div className="pe-5 ps-5">
        <TabsHomePage />
      </div>
      <div className="pe-5 ps-5">
        <div className="mt-4 mb-4 total-data-container">
          <div className="row h-100 w-100">
            <div className="col-12 d-flex flex-column justify-content-center align-items-center">
              {assigningWorkLoader ? (
                <Spin
                  tip="Assigning work, please wait this might take a while..."
                  className="mt-5"
                />
              ) : (
                <Upload
                  className="mt-5"
                  accept=".csv"
                  beforeUpload={(file) => {
                    const reader = new FileReader();
                    setErrorFromUploadCsv("");
                    reader.onload = (e) => {
                      Papa.parse(e.target.result, {
                        complete: function (results) {
                          setAssigningWorkLoader(true);
                          axios
                            .post(url.getCsv, results.data)
                            .then((result) => {
                              setAssigningWorkLoader(false);
                              setResultFromUploadCsv(
                                result.data.data.trackAssignedWork
                              );
                              setErrorFromUploadCsv("");
                            })
                            .catch((error) => {
                              setErrorFromUploadCsv(error);
                              console.log(error);
                              setAssigningWorkLoader(false);
                              setResultFromUploadCsv("");
                            });
                        },
                      });
                    };
                    reader.readAsText(file);

                    // prevent upload
                    return false;
                  }}
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              )}
              {resultFromUploadCsv ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">User</th>
                      <th scope="col">Tasks Assigned</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(resultFromUploadCsv).map((data, index) => {
                      let datum = data.split(" ");
                      return (
                        <tr key={index}>
                          <th scope="row">{`${datum[0]} - ${datum
                            .slice(1)
                            .join(" ")}`}</th>
                          <td>{resultFromUploadCsv[data]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : null}
              {errorFromUploadCsv ? (
                <p>
                  Faced an error in uploading csv, please check the emails and
                  upload again
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignWork;
