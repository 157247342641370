import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import TabsHomePage from "../Home/TabsHomePage";
import { Divider, Switch } from "antd";
import { Tabs } from "antd";

import ProfileCreator from "./ProfileCreator";
import ProfileEditor from "./ProfileEditor";
import { Button, Stack } from "@mui/material";
import { ArrowLeftOutlined } from "@ant-design/icons";

const DummyProfileCreator = ({ loggedInUserDetails, logoutUser }) => {
    const [showTaskAssignedTo, setShowTaskAssignedTo] = useState(true);
    const [profileCreatorOpen, setProfileCreatorOpen] = useState(false);

    const onShowTaskAssignedToCheckedChange = (checked) => {
        if (checked) {
            setShowTaskAssignedTo(true);
        } else {
            setShowTaskAssignedTo(false);
        }
    };

    const showProfileCreator = () => {
        setProfileCreatorOpen(true);
    };
    const goBack = () => {
        setProfileCreatorOpen(false);
    };
    return (
        <div>
            <Header loggedInUserDetails={loggedInUserDetails} logoutUser={logoutUser} />
            {loggedInUserDetails.role === "admin" ? (
                <h6 className="text-center">
                    <Switch
                        checkedChildren="Show all"
                        unCheckedChildren="Show only assigned"
                        className="mx-5"
                        defaultChecked
                        onChange={onShowTaskAssignedToCheckedChange}
                    />
                </h6>
            ) : null}
            <div className="pe-5 ps-5">
                <TabsHomePage />
            </div>
            <div className="pe-5 ps-5">
                <div
                    style={{
                        // border: "1px solid red",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "2rem",
                    }}
                >
                    <Stack>
                        {profileCreatorOpen ? (
                            <>
                                <ProfileCreator goBack={goBack} />
                            </>
                        ) : (
                            <>
                                <ProfileEditor
                                    showProfileCreator={showProfileCreator}
                                    type={"edit_profile"}
                                    loggedInUserDetails={loggedInUserDetails}
                                />
                            </>
                        )}
                    </Stack>
                </div>
            </div>
        </div>
    );
};

export default DummyProfileCreator;
