import { Collapse, Form, Layout, List, message, Result, Spin } from "antd";
import Sider from "antd/lib/layout/Sider";
import { Content } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { url } from "../../url";
import { Box, Button, Divider, Typography } from "@mui/material";
import dayjs from "dayjs";
import RefreshIcon from "@mui/icons-material/Refresh";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useLocation, useOutletContext, useSearchParams } from "react-router-dom";
import RequestForm from "./RequestForm";
import { LoadingOutlined } from "@ant-design/icons";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const { Panel } = Collapse;
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const RefreshHistory = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [companyProfilesApiRefreshStatus, setCompanyProfilesApiRefreshStatus] = useState({ isLoading: true });
  const [companyProfilesDbRefreshStatus, setCompanyProfilesDbRefreshStatus] = useState({ isLoading: true });
  const [refreshHistoryData, setRefreshHistoryData] = useState({ isLoading: false, data: [] });
  const [page, setPage] = useState(-1);
  const [hasMoreHistoryData, setHasMoreHistoryData] = useState(true);
  const [selectedCompanyData, setSelectedCompanyData] = useState();
  const [formValues] = Form.useForm();

  const fetchCompanyProfilesRefreshHistory = async () => {
    try {
      const response = await axios.get(url.companyProfilesRefreshHistory, {
        params: {
          page: page + 1,
        },
      });
      if (response.data.data.length == 0) {
        setHasMoreHistoryData(false);
        return;
      }
      const data = response.data.data.map((ele) => {
        let requestData = ele.eventMetaData.companyProfilesRefreshRequest.requestData;
        let newListItem = {
          id: ele._id,
          requestId: ele.eventMetaData.companyProfilesRefreshRequest.requestId,
          title: requestData.company_name,
          description: dayjs(ele.updatedAt).fromNow(),
          requestData: requestData,
          updatedAt: new Date(ele.updatedAt),
        };
        return newListItem;
      });
      setRefreshHistoryData({ isLoading: false, data: [...refreshHistoryData.data, ...data] });
      setPage(page + 1);
    } catch (e) {
      setRefreshHistoryData({ isLoading: false, data: [] });
      message.error("Failed to fetch refresh history!");
      console.log("Failed to get company profiles refresh history:", e);
    }
  };

  const fetchCompanyProfilesApiRefreshStatus = async (selectedCompany) => {
    try {
      setCompanyProfilesApiRefreshStatus({ isLoading: true });
      const response = await axios({
        method: "get",
        url: url.getCompanyProfilesApiRefreshStatus,
        params: {
          request_id: selectedCompany.requestId,
        },
      });
      setCompanyProfilesApiRefreshStatus({ isLoading: false, data: response?.data?.data });
    } catch (error) {
      setCompanyProfilesApiRefreshStatus({ isLoading: false });
      console.log("Failed to get company profile api refresh status", error);
    }
  };

  const fetchCompanyProfilesDbRefreshStatus = async (selectedCompany) => {
    try {
      setCompanyProfilesDbRefreshStatus({ isLoading: true });
      const response = await axios({
        method: "get",
        url: url.getCompanyProfilesDbRefreshStatus,
        params: {
          label: selectedCompany.requestData.refresh_label,
          company_domain: selectedCompany.requestData.company_domain,
        },
      });
      setCompanyProfilesDbRefreshStatus({ isLoading: false, data: response?.data?.data });
    } catch (error) {
      setCompanyProfilesDbRefreshStatus({ isLoading: false });
      console.log("Failed to get company profile db refresh status", error);
    }
  };

  useEffect(() => {
    fetchCompanyProfilesRefreshHistory();
  }, []);

  const showCompanyProfilesRefreshStatus = (item) => {
    setSelectedCompanyData(item);
    formValues.setFieldsValue(item.requestData);
    fetchCompanyProfilesApiRefreshStatus(item);
    fetchCompanyProfilesDbRefreshStatus(item);
  };

  const openDowloadWindow = (event) => {
    const button = event.currentTarget;
    const source = button.getAttribute("data-source");
    const request_id = selectedCompanyData.requestId;
    const { company_domain, refresh_label } = selectedCompanyData.requestData;
    let downLoadUrl = "";
    if (source === "external-source") {
      downLoadUrl = `${url.downloadRefreshedUidsOfExternalSource}?request_id=${request_id}&company_domain=${company_domain}&refresh_label=${refresh_label}`;
    } else {
      downLoadUrl = `${url.downloadRefreshedUidsOfPublicProfiles}?request_id=${request_id}&company_domain=${company_domain}&refresh_label=${refresh_label}`;
    }
    window.open(downLoadUrl);
  };

  return (
    <Layout
      style={{
        background: "none",
        display: "flex",
        flexDirection: "row",
        margin: "0px 1rem",
        justifyContent: "center",
        padding: "5px",
        borderRadius: "10px",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      }}
    >
      <Sider
        id="inifiniteSlider"
        width={"25%"}
        style={{
          position: "relative",
          background: "none",
          borderRight: "2px solid gray",
          overflow: "auto",
          height: "80vh",
          position: "relative",
          overflowY: "auto",
        }}
      >
        <InfiniteScroll
          dataLength={refreshHistoryData.data.length}
          next={fetchCompanyProfilesRefreshHistory}
          hasMore={hasMoreHistoryData}
          loader={<h4>Loading...</h4>}
          scrollableTarget={"inifiniteSlider"}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>You have seen all history!</b>
            </p>
          }
        >
          {refreshHistoryData.data.map((item) => {
            return (
              <>
                <List.Item
                  key={item.id}
                  onClick={() => showCompanyProfilesRefreshStatus(item)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: selectedCompanyData?.id === item.id ? "#E0FBE2" : "transparent",
                    borderRadius: "5px",
                    padding: "0.7rem 1.5rem 0.7rem 1.5rem",
                  }}
                >
                  <Box width={"100%"} display={"flex"} flexDirection={"column"}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                      <Typography variant="h7" fontWeight={700} fontSize={17} gutterBottom>
                        {item.title}
                      </Typography>
                      <Box display="flex" alignItems="center" color="text.secondary">
                        <FiberManualRecordIcon sx={{ fontSize: 10 }} />
                        <Typography variant="body2" marginLeft={1}>
                          {item.description}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography sx={{ cursor: "text" }} variant="caption">
                      {"request_id:" + item.requestId}
                    </Typography>
                  </Box>
                </List.Item>
                <Divider></Divider>
              </>
            );
          })}
        </InfiniteScroll>
      </Sider>
      <Content style={{ height: "80vh", overflowY: "auto" }}>
        {selectedCompanyData?.requestData ? (
          <div style={{ padding: "8px 2rem 0 2rem" }}>
            <header>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      gap: "0.5rem",
                    }}
                  >
                    <h3>{selectedCompanyData.requestData.company_name}</h3>
                    <p>(Request Id:{selectedCompanyData.requestId})</p>
                  </div>
                  <p>
                    <strong>Refresh Label:</strong> {selectedCompanyData.requestData.refresh_label}
                  </p>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                  <h6>
                    <strong>Domain Name: </strong>
                    <a href={selectedCompanyData.requestData.company_domain}>{selectedCompanyData.requestData.company_domain}</a>
                  </h6>
                  <h6>
                    <strong>Refresh Requested On:</strong>{" "}
                    {selectedCompanyData.updatedAt.toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      hour12: true,
                    })}
                  </h6>
                </div>
              </div>
            </header>
            <hr></hr>
            <section>
              <Collapse>
                <Panel header={"See Full Request"} key="1">
                  <RequestForm
                    isDisabled={true}
                    formValues={formValues}
                    initialCompanyData={selectedCompanyData?.requestData}
                  ></RequestForm>
                </Panel>
              </Collapse>
              <h5 style={{ paddingTop: "1rem" }}>Refresh Status:</h5>
              <hr></hr>
              <div style={{ display: "flex", justifyContent: "center", paddingTop: "0.5rem", minHeight: "60vh" }}>
                <>
                  <div style={{ padding: "0.5rem", display: "flex", flexDirection: "column", width: "50%", borderRight: "2px solid gray" }}>
                    <h6 style={{ alignSelf: "center" }}>Current employees from external sources</h6>

                    <RefreshStatus
                      data={companyProfilesApiRefreshStatus?.data}
                      isLoading={companyProfilesApiRefreshStatus.isLoading}
                    ></RefreshStatus>
                    {companyProfilesApiRefreshStatus?.data?.message === "complete" && (
                      <Button
                        style={{ alignSelf: "center", width: "50%" }}
                        component="label"
                        variant="contained"
                        startIcon={<FileDownloadIcon />}
                        onClick={openDowloadWindow}
                        data-source="external-source"
                      >
                        Download file
                      </Button>
                    )}
                  </div>

                  <div style={{ padding: "0.5rem", display: "flex", flexDirection: "column", width: "50%" }}>
                    <h6 style={{ alignSelf: "center" }}>
                      Existing <strong>{selectedCompanyData.title}</strong> employees from public profiles
                    </h6>
                    {companyProfilesApiRefreshStatus?.data?.message && companyProfilesApiRefreshStatus.data.message === "pending" ? (
                      <Result title="Pending..."></Result>
                    ) : (
                      <>
                        <RefreshStatus
                          data={companyProfilesDbRefreshStatus?.data}
                          isLoading={companyProfilesDbRefreshStatus?.isLoading}
                        ></RefreshStatus>
                      </>
                    )}
                    {companyProfilesDbRefreshStatus?.data?.message === "complete" && (
                      <Button
                        style={{ alignSelf: "center", width: "50%" }}
                        component="label"
                        variant="contained"
                        startIcon={<FileDownloadIcon />}
                        onClick={openDowloadWindow}
                        data-source="public-profile"
                      >
                        Download file
                      </Button>
                    )}
                  </div>
                </>
              </div>
            </section>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center container-height">
            <div>
              <p className="color-grey">
                <i className="fa-solid fa-arrow-pointer me-1"></i>Select left pane to display the details
              </p>
            </div>
          </div>
        )}
      </Content>
    </Layout>
  );
};

const RefreshStatus = ({ data, isLoading }) => {
  if (isLoading) {
    return <Spin style={{ paddingTop: "3rem" }} size="large"></Spin>;
  }
  if (!data) {
    return <Result status="error" title={"Failed to get refresh status!"}></Result>;
  }
  let status = undefined;
  let title = "";
  let extra = <></>;
  if (data.message === "complete") {
    status = "success";
    title = "Successfully Refreshed All Profiles!";
  } else if (data.message === "pending") {
    title = "Refreshing...";
  }

  const thStyle = {
    backgroundColor: "#009879",
    color: "#ffffff",
    textAlign: "left",
    padding: "10px",
  };

  const tdStyle = {
    padding: "10px",
    borderBottom: "1px solid #dddddd",
    textAlign: "left",
  };

  if (data.stats) {
    extra = (
      <>
        <div>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              margin: "20px 0",
              fontSize: "1em",
              fontFamily: "Arial, sans-serif",
              boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)",
            }}
          >
            <thead>
              <tr>
                <th style={thStyle}>Profile Refresh Stats</th>
                <th style={thStyle}>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(data.stats).map(([key, value]) => (
                <tr key={key}>
                  <td style={tdStyle}>{key}</td>
                  <td style={tdStyle}>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  return (
    <div>
      <Result status={status} title={title} extra={[extra]} />
    </div>
  );
};

export default RefreshHistory;
