import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Space, Select, DatePicker, Checkbox } from "antd";

const CandidateForm = ({ form }) => {
    return (
        <Card size="small" title="Candidate Information">
            <Form.Item
                label={"full_name"}
                name={["candidate", "full_name"]}
                rules={[{ required: true, message: "This field is required!" }]}
            >
                <Input />
            </Form.Item>
            <Form.Item label={"about_me"} name={["candidate", "about_me"]}>
                <Input.TextArea />
            </Form.Item>
            {[
                "sname",
                "type",
                "title",
                "address",
                "angel",
                "crunchbase",
                "dob",
                "doximity",
                "dribbble",
                "email",
                "gender",
                "kaggle",
                "lang",
                "linkedin",
                "location_raw",
                "nfx_signal",
                "phone",
                "research_gate",
                "rgate",
                "twitter",
                "num_connections",
            ].map((el, idx) => {
                return (
                    <Form.Item key={idx} label={el} name={["candidate", el]}>
                        {el === "about_me" ? <Input.TextArea /> : <Input />}
                    </Form.Item>
                );
            })}

            <Card size="small" title="Location">
                <Form.Item label="City" name={["candidate", "location", "city"]}>
                    <Input />
                </Form.Item>
                <Form.Item label="State" name={["candidate", "location", "state"]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Country"
                    name={["candidate", "location", "country"]}
                    rules={[{ required: true, message: "This is required field!" }]}
                >
                    <Input />
                </Form.Item>
            </Card>

            {/* Connections */}
            <Card size="small" title="Connections">
                <Form.List name={["candidate", "connections"]}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey }) => (
                                <div key={key}>
                                    <Card
                                        size="small"
                                        title={`Connection ${fieldKey + 1}`}
                                        extra={<MinusCircleOutlined onClick={() => remove(name)} />}
                                    >
                                        <Form.Item name={[name, "ext_src"]} label="External Source">
                                            <Input placeholder="External Source" />
                                        </Form.Item>

                                        {/* Emails Nested List */}
                                        <Form.List name={[name, "emails"]}>
                                            {(emailFields, { add: addEmail, remove: removeEmail }) => (
                                                <Card size="small" title="Emails">
                                                    <>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                marginBottom: 8,
                                                            }}
                                                        >
                                                            {emailFields.map((emailField) => (
                                                                <div
                                                                    key={emailField.key}
                                                                    style={{
                                                                        display: "flex",
                                                                        marginBottom: 8,
                                                                    }}
                                                                    align="baseline"
                                                                >
                                                                    <Form.Item
                                                                        name={[emailField.name, "emails"]}
                                                                        fieldKey={[emailField.fieldKey, "emails"]}
                                                                        label="E-mail"
                                                                        rules={[
                                                                            {
                                                                                type: "email",
                                                                                message: "The input is not valid E-mail!",
                                                                            },
                                                                            {
                                                                                required: true,
                                                                                message: "Please input your E-mail!",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            style={{
                                                                                width: "300px",
                                                                                margin: "0 5px 0 5px",
                                                                            }}
                                                                            placeholder="Email"
                                                                            addonAfter={
                                                                                <MinusCircleOutlined
                                                                                    onClick={() => removeEmail(emailField.name)}
                                                                                />
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <Button type="dashed" onClick={() => addEmail()} block icon={<PlusOutlined />}>
                                                            Add Email
                                                        </Button>
                                                    </>
                                                </Card>
                                            )}
                                        </Form.List>
                                    </Card>
                                </div>
                            ))}
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add Connection
                            </Button>
                        </>
                    )}
                </Form.List>
            </Card>

            {[
                "artstation",
                "behance",
                "codechef",
                "codeforces",
                "connections",
                "ethnicity",
                "github",
                "leetcode",
                "quora",
                "scholar",
                "stackoverflow",
            ].map((field, idx) => (
                <Card key={idx} size="small" title={`${field.charAt(0).toUpperCase() + field.slice(1)}`}>
                    <Form.List name={["candidate", field]}>
                        {(fields, { add, remove }) => (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                }}
                            >
                                {fields.map(({ key, name, fieldKey }) => (
                                    <Space key={key} align="baseline">
                                        <Form.Item
                                            name={[name]}
                                            fieldKey={[fieldKey, field]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `Please input the ${field}`,
                                                },
                                            ]}
                                        >
                                            <Input placeholder={field} addonAfter={<MinusCircleOutlined onClick={() => remove(name)} />} />
                                        </Form.Item>
                                    </Space>
                                ))}
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add {field.charAt(0).toUpperCase() + field.slice(1)}
                                </Button>
                            </div>
                        )}
                    </Form.List>
                </Card>
            ))}

            {/* Candidate Tags */}
            <Card size="small" title="Candidate Tags">
                <Form.List name={["candidate", "candidate_tags"]}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey }) => (
                                <div key={key}>
                                    <Card
                                        size="small"
                                        title={`Tag ${fieldKey + 1}`}
                                        extra={<MinusCircleOutlined onClick={() => remove(name)} />}
                                    >
                                        <Form.Item name={[name, "tag_name"]} label="Tag Name">
                                            <Input placeholder="Tag Name" />
                                        </Form.Item>

                                        <Form.Item name={[name, "tag_desc"]} label="Tag Description">
                                            <Input placeholder="Tag Description" />
                                        </Form.Item>

                                        <Form.Item name={[name, "tag_ts"]} label="Tag Timestamp">
                                            <DatePicker placeholder="Tag Timestamp" />
                                        </Form.Item>
                                        <Form.Item name={[name, "tag_src"]} label="Tag Source">
                                            <Input placeholder="Tag Source" />
                                        </Form.Item>
                                        <Form.Item name={[name, "tag_src_ref"]} label="Tag Source Ref">
                                            <Input placeholder="Tag Source Ref" />
                                        </Form.Item>
                                        <Form.Item name={[name, "hidden"]} label="Hidden" valuePropName="checked">
                                            <Checkbox />
                                        </Form.Item>
                                    </Card>
                                </div>
                            ))}
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add Tag
                            </Button>
                        </>
                    )}
                </Form.List>
            </Card>

            <Card size="small" title="Other Contacts">
                <Form.List name={["candidate", "other_contacts"]}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey }) => (
                                <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                                    <Form.Item
                                        name={[name, "type"]}
                                        fieldKey={[fieldKey, "type"]}
                                        rules={[{ required: true, message: "This is required field!" }]}
                                    >
                                        <Input placeholder="Type" />
                                    </Form.Item>
                                    <Form.Item
                                        name={[name, "id"]}
                                        fieldKey={[fieldKey, "id"]}
                                        rules={[{ required: true, message: "This is required field!" }]}
                                    >
                                        <Input placeholder="ID" />
                                    </Form.Item>
                                    <MinusCircleOutlined disabled={true} onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add Contact
                            </Button>
                        </>
                    )}
                </Form.List>
            </Card>
        </Card>
    );
};

export default CandidateForm;
